.accordion-item {
  display: block;
  margin: .25rem .25rem;
  margin-bottom: 10px;
  border-radius: .5rem;
  box-shadow: 6px 6px .5rem -3px rgba(0,0,0,.10);
  -webkit-box-shadow: 6px 6px .5rem -3px rgba(0,0,0,.20);
  -moz-box-shadow: 6px 6px .5rem -3px rgba(0,0,0,.20);
}

.accordion-title {
  padding: 1rem;
  border: 1px solid rgba(0,0,0,.10);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  text-align: left;
  outline: none;
  transition: 0.5s;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.2rem;
}

.accordion-title-active {
  border-top: 1px solid rgba(0,0,0,.10);
  border-left: 1px solid rgba(0,0,0,.10);
  border-right: 1px solid rgba(0,0,0,.10);
  border-bottom: 1px solid rgba(0,0,0,.10);
  border-radius: .5rem .5rem 0 0;
}

.accordion-title-inactive {
  border-radius: .5rem;
  border: 1px solid rgba(0,0,0,.10);
}

.accordion-content {
  /* margin-top: -12px; */
  overflow: hidden;
  /* padding: 1rem; */
  border-bottom: 1px solid rgba(0,0,0,.10);
  border-left: 1px solid rgba(0,0,0,.10);
  border-right: 1px solid rgba(0,0,0,.10);
  border-radius: 0 0 .5rem 0.5rem;
}

.accordion-title-separator {
  border: none;
  border-top: 1px solid rgba(0,0,0,.10);
  width: 100%;
}

.accordion-content-animation {
  animation: wipe-in-down 0.5s;
}

.accordion-content-animation-reversed {
  animation: wipe-out-up 0.5s;
}

@keyframes wipe-in-down {
  from {
    clip-path: inset(0 0 100% 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

[transition-style="in:wipe:down"] {
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-in-down both;
}

@keyframes wipe-out-up {
  from {
    clip-path: inset(0 0 0 0);
  }
  to {
    clip-path: inset(0 0 100% 0);
  }
}

[transition-style="out:wipe:up"] {
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-out-up both;
}
