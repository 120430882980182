.EndOfFeedDiv {
  width: 100%;
  text-align: center;
  padding: 30px;
}

.EndOfFeedDiv h1 {
  font-size: 25px;
}

.EndOfFeedDiv span {
  color: #BBBBBB;
}
