.cs-document-list-item {
  display: flex;
  margin: .25rem;
  padding: .5rem;
}

.cs-document-list-item-info {
  width: 90%;
  left: auto;
}

.cs-document-list-item-name {
  display: block;
}

.cs-document-list-item-description {
  display: block;
  color: #bbbbbb;
  font-size: .8rem;
}

.cs-document-list-item-download-button {
  max-width: 100%;
  max-height: 100%;
  flex-grow: 1;
  text-align: center;
  vertical-align: sub;
  color: #D96B45;
  background-color: transparent;
}

.cs-document-list-item-download-button-icon {
  max-width: 90%;
  max-height: 90%;
  margin-right: 5px
}
