.Input-icon {
  position: absolute;
  top: 14px;
  left: 18px;
  font-size: 30px;
  color: #D96B45;
  border-right: 2px solid #f5f5f5;
}

.Input-text {
  display: block;
  padding: 23px 25px 23px 74px;
  color: inherit;
  width: 100%;
  font-family: inherit;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: inherit;
  line-height: 14px;
  -webkit-appearance: none;
  border: none;
  border-radius: 1.2rem;
  box-shadow: 0.1rem 0.3rem 1.6rem 1px #e9e9e9 !important;
  -webkit-box-shadow: 0.1rem 0.3rem 1.6rem 1px #e9e9e9 !important;
  -moz-box-shadow: 0.1rem 0.3rem 1.6rem 1px #e9e9e9 !important;
  margin-top: 24px;
  margin-bottom: 24px;
  transition: box-shadow 0.5s;
  background-color: #ffffff;
}

.Input-text::placeholder {
  color: #B0BEC5;
}

.Input-text:focus {
  outline: none;
  box-shadow: 0.2rem 0.3rem 1.6rem #d4d4d4;
}

.Input-button {
  display: block;
  padding: 20px;
  width: 100%;
  background-color: #D96B45;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 14px;
  color:#ffffff;
  border: none;
  border-radius: 5rem;
  box-shadow: 0.1rem 0.3rem 1.6rem #ffe9e9;
  margin-top: 24px;
  margin-bottom: 24px;
  transition: box-shadow 0.5s;
}

.Input-button:disabled,
.Input-button[disabled] {
  background: linear-gradient(0.25turn, #bbbbbb, #666666);
}

.Input-error-info-text {
  display: block;
  padding: 10px;
  width: 100%;
  border: none;
  color: #f50e0b;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  margin-top: -20px;
  margin-bottom: -20px;
}

.main-title {
  margin-top: 10px;
}

.custom-section-title {
  margin-left: 10px;
}

ion-back-button {
  color: #ffffff;
}

ion-note {
  color: #D96B45;
}

ion-tab-bar {
  background-color: #D96B45;
  --background: transparent;
  --color-selected: #FFFFFF;
  --color-focused: #FFFFFF;
  --ripple-color: #FFFFFF;
  --color: #FFFFFF;
}

ion-toolbar {
  /* Both background & --background are needed to fill the container */
  --background: #D96B45;

  --color: #ffffff;

  --border-width: 0px !important;
}

/* https://fkhadra.github.io/react-toastify/how-to-style */
.Toastify__toast-container {
  z-index: 100000;
  padding: 1rem !important;
  margin-top: env(safe-area-inset-top, 1rem) !important;
  border-bottom-left-radius: .5rem !important;
  border-bottom-right-radius: .5rem !important;
  border-top-left-radius: .5rem !important;
  border-top-right-radius: .5rem !important;
  border-radius: .5rem !important;
}

.Toastify__toast {
  border-radius: .5rem !important;
}
.Toastify__toast--error {
  background: linear-gradient(0.25turn, #f50e0b, #a2000a);
}

.Toastify__progress-bar {
  background: linear-gradient(0.25turn, #D96B45, #D96B45);
}
.Toastify__progress-bar--error {
  background: linear-gradient(0.25turn, #bbbbbb, #666666);
}

.fullscreen-modal {
  background-color: #FFF8EB;
}

.fullscreen-modal .modal-back-button {
  position: fixed;
  background-color: transparent;
  color: #D96B45;
  z-index: 100;
  padding-top: env(safe-area-inset-top, 1rem) !important;
}

.fullscreen-modal .modal-back-button ion-icon {
  font-size: 30px;
  padding: 1rem;
}

.fullscreen-modal .modal-cs-icon {
  height: 90px;
  position: relative;
  color: white;
  background-color: #FFF8EB;
  font-size: 25px;
  text-align: center;
  padding-top: env(safe-area-inset-top, 0) !important;
  padding-bottom: 1rem !important;
}

.fullscreen-modal .modal-wrapper {
  --width: 100vw;
  --height: 100vh;
}
