.login-header {
  color: black;
  font-weight:700;
  font-size:25px;
  letter-spacing: 1px;
  text-align: center;
  padding-top:30px;
  margin-top:-40px;
}

.login-subheader {
  color: #868686;
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  padding-top: 13px;
}

.login-subheader-centered {
  padding-left: 5%;
  padding-right: 5%;
}

.login-smooth {
  color: white;
  font-size: 25px;
  text-align: center;
  height: 50px;
}

.version-footer {
  justify-content: center;
  align-items: center;
  display: flex;
  font-size:10px;
  font-style: italic;
  color: #868686;
}

.register-link {
  text-align: center;
}
