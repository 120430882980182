@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
.csfeed-card {
  margin: .25rem;
  border-radius: .5rem;
  border: 1px solid rgba(0,0,0,.10);
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: 0px 7px 0.8rem -3px rgb(0 0 0 / 10%);
  -webkit-box-shadow: 0px 7px 0.8rem -3px rgb(0 0 0 / 10%);
  -moz-box-shadow: 0px 7px 0.8rem -3px rgb(0 0 0 / 10%);
  margin-bottom: 25px;
}

.csfeed-card-header {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  border-bottom: 1px solid lightgray;
  padding: 15px;
  text-transform: capitalize;
  font-size: 17px;
}

.csfeed-card-content {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px;
}

.csfeed-card-age {
  display: block;
  color: #bbbbbb;
  font-size: .8rem;
}

.csfeed-card-age-icon {
  vertical-align: middle;
  margin-top: -2px;
}

.csfeed-card-gallery {
  width: 100%;
  height: 100vw;
}

.csfeed-card-toolbar {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  align-items: center;
  display: inline-flex;
}

.csfeed-card-toolbar ion-button {
  --padding-start: 0px !important;
  --padding-end: 0px !important;
}

.csfeed-card-toolbar-left {
  width: 25%;
  height: -moz-fit-content;
  height: fit-content;
  float: left;
  left: 0px;
  display: inline;
  position: absolute;
}

.csfeed-card-toolbar-center {
  width: 50%;
  height: -moz-fit-content;
  height: fit-content;
  left: 25%;
  text-align: center;
  position: absolute;
}

.csfeed-card-toolbar-right {
  width: 25%;
  height: -moz-fit-content;
  height: fit-content;
  left: 75%;
  text-align: right;
  display: inline;
  position: absolute;
}

.csfeed-card-gallery .csfeed-card-gallery-slider {
  width: 100%;
  height: 100vw;
}

.csfeed-card-gallery .csfeed-card-gallery-content {
  width: 100%;
  position: relative;
}

.csfeed-card-gallery .csfeed-card-gallery-image {
  width: 100%;
  display: inline;
  height: 100vw;
  object-fit: cover;
}

.csfeed-card-gallery .csfeed-card-gallery-image-description {
  position: absolute;
  bottom: 5px;
  /* left: 50%; */
  /* transform: translate(-50%, 0%); */
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 5px;
  background-color: rgba(217,107,69,0.7);
  color: white;
  text-align: left;
}

.csfeed-card-button {
  margin-right: 1rem;
}

.csfeed-card-button-icon {
  color: #D96B45;
}

.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #D96B45;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.hide-element {
  display: none;
}

.blur {
  animation: blur 1s
}

@keyframes blur {
  0% {
    filter: blur(1.5rem);
  }
  100% {
    filter: blur(0);
  }
}


[transition-style="blur"] {
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) blur both;
}

.opacity {
  animation: opacity_animation 1s
}

@keyframes opacity_animation {
  0% {
    opacity: 100;
  }
  60% {
    opacity: 0;
  }
}

[transition-style="opacity"] {
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) opacity_animation both;
}

.cs-feed-card-modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  height: 100vh;
  display: flex;
  align-items: center;
  animation: circle-in-center 0.6s;
}

.cs-feed-card-modal-image {
  width: 100vw;
}

@keyframes circle-in-center {
  from {
    clip-path: circle(0%);
  }
  to {
    clip-path: circle(125%);
  }
}

[transition-style="in:circle:center"] {
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) circle-in-center both;
}

.EndOfFeedDiv {
  width: 100%;
  text-align: center;
  padding: 30px;
}

.EndOfFeedDiv h1 {
  font-size: 25px;
}

.EndOfFeedDiv span {
  color: #BBBBBB;
}

.accordion-item {
  display: block;
  margin: .25rem .25rem;
  margin-bottom: 10px;
  border-radius: .5rem;
  box-shadow: 6px 6px .5rem -3px rgba(0,0,0,.10);
  -webkit-box-shadow: 6px 6px .5rem -3px rgba(0,0,0,.20);
  -moz-box-shadow: 6px 6px .5rem -3px rgba(0,0,0,.20);
}

.accordion-title {
  padding: 1rem;
  border: 1px solid rgba(0,0,0,.10);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  text-align: left;
  outline: none;
  transition: 0.5s;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.2rem;
}

.accordion-title-active {
  border-top: 1px solid rgba(0,0,0,.10);
  border-left: 1px solid rgba(0,0,0,.10);
  border-right: 1px solid rgba(0,0,0,.10);
  border-bottom: 1px solid rgba(0,0,0,.10);
  border-radius: .5rem .5rem 0 0;
}

.accordion-title-inactive {
  border-radius: .5rem;
  border: 1px solid rgba(0,0,0,.10);
}

.accordion-content {
  /* margin-top: -12px; */
  overflow: hidden;
  /* padding: 1rem; */
  border-bottom: 1px solid rgba(0,0,0,.10);
  border-left: 1px solid rgba(0,0,0,.10);
  border-right: 1px solid rgba(0,0,0,.10);
  border-radius: 0 0 .5rem 0.5rem;
}

.accordion-title-separator {
  border: none;
  border-top: 1px solid rgba(0,0,0,.10);
  width: 100%;
}

.accordion-content-animation {
  animation: wipe-in-down 0.5s;
}

.accordion-content-animation-reversed {
  animation: wipe-out-up 0.5s;
}

@keyframes wipe-in-down {
  from {
    clip-path: inset(0 0 100% 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

[transition-style="in:wipe:down"] {
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-in-down both;
}

@keyframes wipe-out-up {
  from {
    clip-path: inset(0 0 0 0);
  }
  to {
    clip-path: inset(0 0 100% 0);
  }
}

[transition-style="out:wipe:up"] {
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-out-up both;
}

.accordion {
  margin: .25rem auto;
}

.cs-document-list-item {
  display: flex;
  margin: .25rem;
  padding: .5rem;
}

.cs-document-list-item-info {
  width: 90%;
  left: auto;
}

.cs-document-list-item-name {
  display: block;
}

.cs-document-list-item-description {
  display: block;
  color: #bbbbbb;
  font-size: .8rem;
}

.cs-document-list-item-download-button {
  max-width: 100%;
  max-height: 100%;
  flex-grow: 1;
  text-align: center;
  vertical-align: sub;
  color: #D96B45;
  background-color: transparent;
}

.cs-document-list-item-download-button-icon {
  max-width: 90%;
  max-height: 90%;
  margin-right: 5px
}

.cs-document-list {

}

.cs-document-list-separator {
  border: none;
  border-top: 1px solid rgba(0,0,0,.10);
  width: 80%;
}



.login-header {
  color: black;
  font-weight:700;
  font-size:25px;
  letter-spacing: 1px;
  text-align: center;
  padding-top:30px;
  margin-top:-40px;
}

.login-subheader {
  color: #868686;
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  padding-top: 13px;
}

.login-subheader-centered {
  padding-left: 5%;
  padding-right: 5%;
}

.login-smooth {
  color: white;
  font-size: 25px;
  text-align: center;
  height: 50px;
}

.version-footer {
  justify-content: center;
  align-items: center;
  display: flex;
  font-size:10px;
  font-style: italic;
  color: #868686;
}

.register-link {
  text-align: center;
}

.firststart-header {
  color: black;
  font-weight:700;
  font-size:25px;
  letter-spacing: 1px;
  text-align: center;
  padding-top:30px;
  margin-top:-40px;
}

.firststart-subheader {
  color: #868686;
  font-weight:300;
  font-size:14px;
  text-transform: uppercase;
  text-align: center;
  padding-top:13px;
}

.firststart-smooth {
  color: white;
  font-size:25px;
  text-align: center;
  height:50px;
}

.login-header {
  color: black;
  font-weight:700;
  font-size:25px;
  letter-spacing: 1px;
  text-align: center;
  padding-top:30px;
}

.login-subheader {
  color: #868686;
  font-weight:300;
  font-size:14px;
  text-transform: uppercase;
  text-align: center;
  padding-top:13px;
}

.login-smooth {
  color: white;
  font-size:25px;
  text-align: center;
  height:50px;
}

.version-footer {
  justify-content: center;
  align-items: center;
  display: flex;
  font-size:10px;
  font-style: italic;
  color: #868686;
}

.Input-icon {
  position: absolute;
  top: 14px;
  left: 18px;
  font-size: 30px;
  color: #D96B45;
  border-right: 2px solid #f5f5f5;
}

.Input-text {
  display: block;
  padding: 23px 25px 23px 74px;
  color: inherit;
  width: 100%;
  font-family: inherit;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: inherit;
  line-height: 14px;
  -webkit-appearance: none;
  border: none;
  border-radius: 1.2rem;
  box-shadow: 0.1rem 0.3rem 1.6rem 1px #e9e9e9 !important;
  -webkit-box-shadow: 0.1rem 0.3rem 1.6rem 1px #e9e9e9 !important;
  -moz-box-shadow: 0.1rem 0.3rem 1.6rem 1px #e9e9e9 !important;
  margin-top: 24px;
  margin-bottom: 24px;
  transition: box-shadow 0.5s;
  background-color: #ffffff;
}

.Input-text::placeholder {
  color: #B0BEC5;
}

.Input-text:focus {
  outline: none;
  box-shadow: 0.2rem 0.3rem 1.6rem #d4d4d4;
}

.Input-button {
  display: block;
  padding: 20px;
  width: 100%;
  background-color: #D96B45;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 14px;
  color:#ffffff;
  border: none;
  border-radius: 5rem;
  box-shadow: 0.1rem 0.3rem 1.6rem #ffe9e9;
  margin-top: 24px;
  margin-bottom: 24px;
  transition: box-shadow 0.5s;
}

.Input-button:disabled,
.Input-button[disabled] {
  background: linear-gradient(0.25turn, #bbbbbb, #666666);
}

.Input-error-info-text {
  display: block;
  padding: 10px;
  width: 100%;
  border: none;
  color: #f50e0b;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  margin-top: -20px;
  margin-bottom: -20px;
}

.main-title {
  margin-top: 10px;
}

.custom-section-title {
  margin-left: 10px;
}

ion-back-button {
  color: #ffffff;
}

ion-note {
  color: #D96B45;
}

ion-tab-bar {
  background-color: #D96B45;
  --background: transparent;
  --color-selected: #FFFFFF;
  --color-focused: #FFFFFF;
  --ripple-color: #FFFFFF;
  --color: #FFFFFF;
}

ion-toolbar {
  /* Both background & --background are needed to fill the container */
  --background: #D96B45;

  --color: #ffffff;

  --border-width: 0px !important;
}

/* https://fkhadra.github.io/react-toastify/how-to-style */
.Toastify__toast-container {
  z-index: 100000;
  padding: 1rem !important;
  margin-top: env(safe-area-inset-top, 1rem) !important;
  border-bottom-left-radius: .5rem !important;
  border-bottom-right-radius: .5rem !important;
  border-top-left-radius: .5rem !important;
  border-top-right-radius: .5rem !important;
  border-radius: .5rem !important;
}

.Toastify__toast {
  border-radius: .5rem !important;
}
.Toastify__toast--error {
  background: linear-gradient(0.25turn, #f50e0b, #a2000a);
}

.Toastify__progress-bar {
  background: linear-gradient(0.25turn, #D96B45, #D96B45);
}
.Toastify__progress-bar--error {
  background: linear-gradient(0.25turn, #bbbbbb, #666666);
}

.fullscreen-modal {
  background-color: #FFF8EB;
}

.fullscreen-modal .modal-back-button {
  position: fixed;
  background-color: transparent;
  color: #D96B45;
  z-index: 100;
  padding-top: env(safe-area-inset-top, 1rem) !important;
}

.fullscreen-modal .modal-back-button ion-icon {
  font-size: 30px;
  padding: 1rem;
}

.fullscreen-modal .modal-cs-icon {
  height: 90px;
  position: relative;
  color: white;
  background-color: #FFF8EB;
  font-size: 25px;
  text-align: center;
  padding-top: env(safe-area-inset-top, 0) !important;
  padding-bottom: 1rem !important;
}

.fullscreen-modal .modal-wrapper {
  --width: 100vw;
  --height: 100vh;
}

/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {

  --ion-font-family:'Quicksand', sans-serif;

  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

/*@media (prefers-color-scheme: dark) {*/
/*  !**/
/*   * Dark Colors*/
/*   * -------------------------------------------*/
/*   *!*/

/*  body {*/
/*    --ion-color-primary: #428cff;*/
/*    --ion-color-primary-rgb: 66,140,255;*/
/*    --ion-color-primary-contrast: #ffffff;*/
/*    --ion-color-primary-contrast-rgb: 255,255,255;*/
/*    --ion-color-primary-shade: #3a7be0;*/
/*    --ion-color-primary-tint: #5598ff;*/

/*    --ion-color-secondary: #50c8ff;*/
/*    --ion-color-secondary-rgb: 80,200,255;*/
/*    --ion-color-secondary-contrast: #ffffff;*/
/*    --ion-color-secondary-contrast-rgb: 255,255,255;*/
/*    --ion-color-secondary-shade: #46b0e0;*/
/*    --ion-color-secondary-tint: #62ceff;*/

/*    --ion-color-tertiary: #6a64ff;*/
/*    --ion-color-tertiary-rgb: 106,100,255;*/
/*    --ion-color-tertiary-contrast: #ffffff;*/
/*    --ion-color-tertiary-contrast-rgb: 255,255,255;*/
/*    --ion-color-tertiary-shade: #5d58e0;*/
/*    --ion-color-tertiary-tint: #7974ff;*/

/*    --ion-color-success: #2fdf75;*/
/*    --ion-color-success-rgb: 47,223,117;*/
/*    --ion-color-success-contrast: #000000;*/
/*    --ion-color-success-contrast-rgb: 0,0,0;*/
/*    --ion-color-success-shade: #29c467;*/
/*    --ion-color-success-tint: #44e283;*/

/*    --ion-color-warning: #ffd534;*/
/*    --ion-color-warning-rgb: 255,213,52;*/
/*    --ion-color-warning-contrast: #000000;*/
/*    --ion-color-warning-contrast-rgb: 0,0,0;*/
/*    --ion-color-warning-shade: #e0bb2e;*/
/*    --ion-color-warning-tint: #ffd948;*/

/*    --ion-color-danger: #ff4961;*/
/*    --ion-color-danger-rgb: 255,73,97;*/
/*    --ion-color-danger-contrast: #ffffff;*/
/*    --ion-color-danger-contrast-rgb: 255,255,255;*/
/*    --ion-color-danger-shade: #e04055;*/
/*    --ion-color-danger-tint: #ff5b71;*/

/*    --ion-color-dark: #f4f5f8;*/
/*    --ion-color-dark-rgb: 244,245,248;*/
/*    --ion-color-dark-contrast: #000000;*/
/*    --ion-color-dark-contrast-rgb: 0,0,0;*/
/*    --ion-color-dark-shade: #d7d8da;*/
/*    --ion-color-dark-tint: #f5f6f9;*/

/*    --ion-color-medium: #989aa2;*/
/*    --ion-color-medium-rgb: 152,154,162;*/
/*    --ion-color-medium-contrast: #000000;*/
/*    --ion-color-medium-contrast-rgb: 0,0,0;*/
/*    --ion-color-medium-shade: #86888f;*/
/*    --ion-color-medium-tint: #a2a4ab;*/

/*    --ion-color-light: #222428;*/
/*    --ion-color-light-rgb: 34,36,40;*/
/*    --ion-color-light-contrast: #ffffff;*/
/*    --ion-color-light-contrast-rgb: 255,255,255;*/
/*    --ion-color-light-shade: #1e2023;*/
/*    --ion-color-light-tint: #383a3e;*/
/*  }*/

/*  !**/
/*   * iOS Dark Theme*/
/*   * -------------------------------------------*/
/*   *!*/

/*  .ios body {*/
/*    --ion-background-color: #000000;*/
/*    --ion-background-color-rgb: 0,0,0;*/

/*    --ion-text-color: #ffffff;*/
/*    --ion-text-color-rgb: 255,255,255;*/

/*    --ion-color-step-50: #0d0d0d;*/
/*    --ion-color-step-100: #1a1a1a;*/
/*    --ion-color-step-150: #262626;*/
/*    --ion-color-step-200: #333333;*/
/*    --ion-color-step-250: #404040;*/
/*    --ion-color-step-300: #4d4d4d;*/
/*    --ion-color-step-350: #595959;*/
/*    --ion-color-step-400: #666666;*/
/*    --ion-color-step-450: #737373;*/
/*    --ion-color-step-500: #808080;*/
/*    --ion-color-step-550: #8c8c8c;*/
/*    --ion-color-step-600: #999999;*/
/*    --ion-color-step-650: #a6a6a6;*/
/*    --ion-color-step-700: #b3b3b3;*/
/*    --ion-color-step-750: #bfbfbf;*/
/*    --ion-color-step-800: #cccccc;*/
/*    --ion-color-step-850: #d9d9d9;*/
/*    --ion-color-step-900: #e6e6e6;*/
/*    --ion-color-step-950: #f2f2f2;*/

/*    --ion-item-background: #000000;*/

/*    --ion-card-background: #1c1c1d;*/
/*  }*/

/*  .ios ion-modal {*/
/*    --ion-background-color: var(--ion-color-step-100);*/
/*    --ion-toolbar-background: var(--ion-color-step-150);*/
/*    --ion-toolbar-border-color: var(--ion-color-step-250);*/
/*  }*/


/*  !**/
/*   * Material Design Dark Theme*/
/*   * -------------------------------------------*/
/*   *!*/

/*  .md body {*/
/*    --ion-background-color: #121212;*/
/*    --ion-background-color-rgb: 18,18,18;*/

/*    --ion-text-color: #ffffff;*/
/*    --ion-text-color-rgb: 255,255,255;*/

/*    --ion-border-color: #222222;*/

/*    --ion-color-step-50: #1e1e1e;*/
/*    --ion-color-step-100: #2a2a2a;*/
/*    --ion-color-step-150: #363636;*/
/*    --ion-color-step-200: #414141;*/
/*    --ion-color-step-250: #4d4d4d;*/
/*    --ion-color-step-300: #595959;*/
/*    --ion-color-step-350: #656565;*/
/*    --ion-color-step-400: #717171;*/
/*    --ion-color-step-450: #7d7d7d;*/
/*    --ion-color-step-500: #898989;*/
/*    --ion-color-step-550: #949494;*/
/*    --ion-color-step-600: #a0a0a0;*/
/*    --ion-color-step-650: #acacac;*/
/*    --ion-color-step-700: #b8b8b8;*/
/*    --ion-color-step-750: #c4c4c4;*/
/*    --ion-color-step-800: #d0d0d0;*/
/*    --ion-color-step-850: #dbdbdb;*/
/*    --ion-color-step-900: #e7e7e7;*/
/*    --ion-color-step-950: #f3f3f3;*/

/*    --ion-item-background: #1e1e1e;*/

/*    --ion-toolbar-background: #1f1f1f;*/

/*    --ion-tab-bar-background: #1f1f1f;*/

/*    --ion-card-background: #1e1e1e;*/
/*  }*/
/*}*/


