.csfeed-card {
  margin: .25rem;
  border-radius: .5rem;
  border: 1px solid rgba(0,0,0,.10);
  height: fit-content;
  box-shadow: 0px 7px 0.8rem -3px rgb(0 0 0 / 10%);
  -webkit-box-shadow: 0px 7px 0.8rem -3px rgb(0 0 0 / 10%);
  -moz-box-shadow: 0px 7px 0.8rem -3px rgb(0 0 0 / 10%);
  margin-bottom: 25px;
}

.csfeed-card-header {
  width: 100%;
  height: fit-content;
  border-bottom: 1px solid lightgray;
  padding: 15px;
  text-transform: capitalize;
  font-size: 17px;
}

.csfeed-card-content {
  width: 100%;
  height: fit-content;
  padding: 8px;
}

.csfeed-card-age {
  display: block;
  color: #bbbbbb;
  font-size: .8rem;
}

.csfeed-card-age-icon {
  vertical-align: middle;
  margin-top: -2px;
}

.csfeed-card-gallery {
  width: 100%;
  height: 100vw;
}

.csfeed-card-toolbar {
  width: 100%;
  height: fit-content;
  align-items: center;
  display: inline-flex;
}

.csfeed-card-toolbar ion-button {
  --padding-start: 0px !important;
  --padding-end: 0px !important;
}

.csfeed-card-toolbar-left {
  width: 25%;
  height: fit-content;
  float: left;
  left: 0px;
  display: inline;
  position: absolute;
}

.csfeed-card-toolbar-center {
  width: 50%;
  height: fit-content;
  left: 25%;
  text-align: center;
  position: absolute;
}

.csfeed-card-toolbar-right {
  width: 25%;
  height: fit-content;
  left: 75%;
  text-align: right;
  display: inline;
  position: absolute;
}

.csfeed-card-gallery .csfeed-card-gallery-slider {
  width: 100%;
  height: 100vw;
}

.csfeed-card-gallery .csfeed-card-gallery-content {
  width: 100%;
  position: relative;
}

.csfeed-card-gallery .csfeed-card-gallery-image {
  width: 100%;
  display: inline;
  height: 100vw;
  object-fit: cover;
}

.csfeed-card-gallery .csfeed-card-gallery-image-description {
  position: absolute;
  bottom: 5px;
  /* left: 50%; */
  /* transform: translate(-50%, 0%); */
  width: 100%;
  height: fit-content;
  padding: 5px;
  background-color: rgba(217,107,69,0.7);
  color: white;
  text-align: left;
}

.csfeed-card-button {
  margin-right: 1rem;
}

.csfeed-card-button-icon {
  color: #D96B45;
}

.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #D96B45;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.hide-element {
  display: none;
}

.blur {
  animation: blur 1s
}

@keyframes blur {
  0% {
    filter: blur(1.5rem);
  }
  100% {
    filter: blur(0);
  }
}


[transition-style="blur"] {
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) blur both;
}

.opacity {
  animation: opacity_animation 1s
}

@keyframes opacity_animation {
  0% {
    opacity: 100;
  }
  60% {
    opacity: 0;
  }
}

[transition-style="opacity"] {
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) opacity_animation both;
}
