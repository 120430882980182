.firststart-header {
  color: black;
  font-weight:700;
  font-size:25px;
  letter-spacing: 1px;
  text-align: center;
  padding-top:30px;
  margin-top:-40px;
}

.firststart-subheader {
  color: #868686;
  font-weight:300;
  font-size:14px;
  text-transform: uppercase;
  text-align: center;
  padding-top:13px;
}

.firststart-smooth {
  color: white;
  font-size:25px;
  text-align: center;
  height:50px;
}
